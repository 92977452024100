import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import OrderService from '../../../services/Order.service';
import CustomerHeader from "../../../components/layout/CustomerHeader";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import OrderListAction from "../../../redux/actions/OrderList.action";
import moment from 'moment';
import OrderAction from "../../../redux/actions/Order.action";
import $ from 'jquery';
// import DataTable from 'react-data-table-component';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import CustomerAction from "../../../redux/actions/Customer.action";

class OrderHistory extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef();
        this.state = {
            customer: null,
            orders: [],
            orderList: [],
            allOrders: [],
            selectedOrders: null,

        }

    }

    static getDerivedStateFromProps(props, state) {
        return {
            customer: props.customer || null,
            orders: props.orders || [],
            allOrders: props.allOrders || [],
            selectedOrders: props.selectedOrders || null,
        }
    }

    getOrders = () => {
        let customer = this.state.customer
        if (customer) {
            OrderService.getOrders(customer._id).then(data => {
                console.log(data, "orderHistory")
            })
        }
    }

    componentDidMount() {
        console.log(this.state.customer, "customer");
        let customer = this.state.customer
        if (customer) {
            OrderService.getOrders(customer._id).then(data => {
                console.log(data.data, "orderHistory")
                if (data.data && data.data.success) {
                    this.setState({ orderList: data.data.data.order });
                    this.setState({ orders: data.data.data.order });

                    this.props.saveAllOrders(data.data.data.order);
                    // this.dataTable = $(this.tableRef.current).DataTable();

                }
            })
        }


        // this.getOrders()
        // setTimeout(() => {
        //     console.log(this.state, 89898);
        // }, 10000);
        // const orders = OrderListService.getOrders();
        // const customer = this.props.CustomerReducer.customer;
        // console.log("all orders");
        // console.log(customer);
    }
    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    handleClickHome = () => {

        this.props.navigate('/customer/registration');
    }
    handleTrackOrderClick = (event) => {

        const allOrder = this.props.allOrders;
        const targetId = event.target.name;

        const filteredItem = allOrder.find(item => item._id === targetId);

        console.log(allOrder, "all orders details");
        console.log(filteredItem, "filter orders");
        this.setState({ selectedOrders: filteredItem });

        this.props.saveSelectedOrders(filteredItem);


        this.props.navigate('/customer/order-info');
    }

    setOrderHistoryData = (data) => {
        console.log(data, 99999);
        let resp = []
        for (let i = 0; i < data.length; i++) {
            const totalqty = data[i].products.reduce((totalQuantity, product) => totalQuantity + product.quantity, 0);
            resp.push({
                _id: data[i]._id,
                order_id: data[i].order_id,
                createdAt: data[i].createdAt,
                total: data[i].total,
                address: data[i].address ? (data[i].address.addressLine1 + "," + data[i].address.town + "," + data[i].address.country + "," + data[i].address.state + "," + data[i].address.pin) : "",
                orderStatus: data[i].orderStatus ? data[i].orderStatus.charAt(0).toUpperCase() + data[i].orderStatus.slice(1).toLowerCase() : "",
                total_product_qty: totalqty + (data[i].free_pen_quantity ? data[i].free_pen_quantity : 0),
            })
        }
        return resp
    }

    handleReorder = (event) => {
        const targetId = event.target.name;
        const allOrder = this.props.allOrders;
        const filteredItem = allOrder.find(item => item._id === targetId);
        this.props.saveSelectedOrders(filteredItem);
        let pagename = 'Order';
        this.props.setPagename(pagename)
        this.props.navigate('/customer/place-order');
        console.log(filteredItem, 33333);
    }

    render = () => {
        const { orders } = this.state
        let _data = this.setOrderHistoryData(this.state.orderList)
        // console.log(_data,"alldata orders");
        return (

            <>
                {/* <section className="landing-page-pt pt-0">
                    <div className="container">
                        <CustomerHeader />
                    </div>
               
                    <div className="mr-landing-page">
                        <div className="landing-head-pt">
                            <h2>Order History</h2>
                        </div>

                        
                            <div className="cl-inpt-part-st">
                                <input type="text" className="form-control mbl-inpt-part" placeholder="Search Doctor"/>
                            </div>
                        
                    </div>
                </section> */}

                {/*landing-page-details------*/}
                <section className="landing-profl-detls pt-4">
                    <div className="container">
                        <CustomerHeader />
                        <div className="mr-landing-page">
                            <div className="landing-head-pt">
                                <h2 className="ordertitle">Order History</h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="title">
                                    <h5 className="mb-0">All Order Details</h5>
                                </div>
                                <div className="btn-home">
                                    <a href="javascript:void(0);" onClick={this.handleClickHome}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-home"
                                        >
                                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                            <polyline points="9 22 9 12 15 12 15 22" />
                                        </svg>
                                        Home
                                    </a>
                                </div>
                            </div>

                            {/* <div className="cl-inpt-part-st">
                                <input type="text" className="form-control mbl-inpt-part" placeholder="Search Doctor"/>
                            </div> */}

                        </div>
                        {
                            _data && _data.length &&
                            <CustomDatatable
                                pagination={true}
                                columns={[
                                    {
                                        name: 'Order ID',
                                        selector: row => row.order_id,

                                    },
                                    {
                                        name: 'Order Date',
                                        selector: row => row.createdAt,

                                    },
                                    {
                                        name: 'Total Qty.',
                                        // selector: row => row.total,

                                    },
                                    {
                                        name: 'Total Amount',
                                        selector: row => row.total,

                                    },
                                    {
                                        name: 'Delivery Address',
                                        // selector: row => row.createdAt,

                                    },
                                    {
                                        name: 'Order Status',
                                        selector: row => row.orderStatus,

                                    },
                                    {
                                        name: 'Track Order',
                                        // selector: row => row.createdAt,

                                    },
                                    {
                                        name: 'Re Order',
                                        // selector: row => row.createdAt,

                                    }
                                ]}
                                data={this.setOrderHistoryData(this.state.orderList)}
                                handleTrackOrderClick={this.handleTrackOrderClick}
                                handleReorder={this.handleReorder}
                                pageName="orderhistory"
                            />
                        }
                        {/* <div className="landing-form-dtls">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="title">
                                    <h5 className="mb-0">All Order Details</h5>
                                </div>
                                <div className="btn-home">
                                    <a href="javascript:void(0);" onClick={this.handleClickHome}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-home"
                                        >
                                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                            <polyline points="9 22 9 12 15 12 15 22" />
                                        </svg>
                                        Home
                                    </a>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table
                                   
                                    className="table table-striped"
                                    style={{ width: "100%" }}
                                    ref={this.tableRef}
                                >
                                    <thead>
                                        <tr>
                                            
                                            <th width="12%">Order ID</th>
                                            <th width="12%">Order Date</th>
                                            <th width="12%">Total Amount</th>
                                            <th width="14%">Address</th>
                                            <th width="12%">Status</th>
                                            <th width="12%">Track Order</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    {this.state.orderList.map((item, index) => (
                                        <tr id={"row"+index}>
                                            <td id = {"first"+index}>ID-{item.order_id}</td>
                                            <td  id={"second"+index}>{moment(item.createdAt).format('MM-DD-YYYY')}</td>
                                            <td  id={"third"+index}>₹{item.total}</td>
                                            <td  id={"fourth"+index}>Address-{item.customer.address.addressLine1+","+item.customer.address.town+","+item.customer.address.country+","+item.customer.address.state+","+item.customer.address.pin}</td>
                                            <td  id={"fifth"+index}>
                                                <button type="button" className="landing-pending-btn">
                                                    Pending
                                                </button>
                                            </td>
                                            <td key={"sixth"+index} id={index}>
                                                <div className="track-order-btn">
                                                    <a href="#" onClick={this.handleTrackOrderClick} name={item._id}> Track Order </a>
                                                </div>
                                            </td>
                                        </tr>

                                    ))}
                                     
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                        <CustomerFooter />
                    </div>
                </section>
                {/*landing-page-details-end------*/}
            </>





        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setOrders: (orders) => dispatch(OrderListAction.getOrderList(orders)),
        saveAllOrders: (allOrders) => dispatch(OrderAction.saveAllOrders(allOrders)),
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        setPagename: (pagename) => dispatch(CustomerAction.setPagename(pagename))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { allOrders } = OrderReducer
    const { selectedOrders } = OrderReducer
    console.log(customer, 123);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        allOrders,
        selectedOrders
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(OrderHistory)))