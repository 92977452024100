import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
//reducers
import CommonReducer from "./reducers/Common.reducer";
import CustomerReducer from "./reducers/Customer.reducer";
import OrderReducer from "./reducers/Order.reducer";
import MrReducer from "./reducers/Mr.reducer";
import FeedbackReducer from "./reducers/Feedback.reducer";
import CallCenterReducer from "./reducers/CallCenter.reducer";
import DoctorReducer from "./reducers/Doctor.reducer";
import StockistReducer from "./reducers/Stockist.reducer";
import ProductReducer from "./reducers/Product.reducer";
import AdminReducer from "./reducers/Admin.reducer";
import AdminUserReducer from "./reducers/AdminUser.reducer";
// import OrderListReducer from "./reducers/OrderList.reducer";
const appReducer = combineReducers({
  CommonReducer: CommonReducer,
  CustomerReducer: CustomerReducer,
  OrderReducer: OrderReducer,
  MrReducer: MrReducer,
  FeedbackReducer: FeedbackReducer,
  CallCenterReducer: CallCenterReducer,
  DoctorReducer: DoctorReducer,
  StockistReducer: StockistReducer,
  ProductReducer: ProductReducer,
  AdminReducer: AdminReducer,
  AdminUserReducer: AdminUserReducer
  // OrderListReducer: OrderListReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

let verifyToken = (store) => {
  return next => {
    return action => {
      return next(action)
    }
  }
}

let store = createStore(persistedReducer, applyMiddleware(verifyToken))
let persistor = persistStore(store)
export { store, persistor }
