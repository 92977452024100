import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import BackButton from "../Reusable/BackButton";
import DropdownCoupon from '../../components/SearchableDropdown/DropdownCoupon.component';
import Dropdown from "../SearchableDropdown/Dropdown.component";



export default function OrderList({ products, paymentOrder, placeOrder, product_quantities, couponList, selectedValue, onValueChange, applyCoupon, couponDiscount, isShowApplyBtn, removeCoupon, handleCheckbox, isChecked, changeVal, addressLine1, pin, countries, country, states, towns, town, state, setStateRegistrationData, populateStates, populateTowns, showTowns, registration, freepenQuantity, freepenPrice, isCheckFreePen, schemeCouponList }) {

    const handleChange = (item) => {
        // const item = event.target.value;
        onValueChange && onValueChange(item); // Invoke the callback to send the value to the parent
    };

    const handleInputChange = (newValue) => {
        // const newValue = event.target.value;
        onValueChange(newValue); // Invoke the callback to send the value to the parent
    };
    const productNameJsx = () => {
        return products.map((product, i) => {
            return product_quantities[i]?.quantity ? <h6>
                <span className="ellipsis">
                    {product.name}
                </span>
            </h6> : null
        })
    }

    const paymentQuantity = () => {
        return products.map((product, i) => {
            // console.log(product_quantities[i], "ljksdfhjklshjkdfhsjksg")
            return product_quantities[i]?.quantity ? <>
                <h6>₹{product.price} X {product_quantities[i].quantity}</h6>
            </> : null
        })
    }

    /*******  Current date print by smrajit 25_07_2023  ****************/


    const CurrentDateComponent = () => {
        // Create a new Date object with the desired date (June 25, 2023)
        const date = new Date();

        // Define an array of month names
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Get the parts of the date (day, month, year)
        const day = String(date.getDate()).padStart(2, '0');
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        // Format the date string (e.g., "25-Jun-2023")
        const formattedDate = `${day}-${month}-${year}`;

        return (
            <div>
                {formattedDate}
            </div>
        );
    };

    /*  End */



    return (
        <>
            <div className="title-wrap mt-4">
                {/* <h6 className="sm-title text-uppercase mb-15">Medicine</h6> */}
                {/* <h1 className="lg-title text-capitalize mb-20">EMROK</h1> */}
            </div>
            {/* Title end*/}

            {/* Title end with date */}
            <div className="row">
                <div className="col-8 col-md-9">
                    <div className="row">
                        <h5 className="col-md-6 md-title mb-3 pt-3 ">Order Details</h5>
                        <div className="col-md-6 md-title pe-5 d-flex align-items-center datediv">
                            <span className="m-0 me-2">Date:</span>
                            <span className="m-0 order-date">{CurrentDateComponent()}</span>
                        </div>
                    </div>
                    <div className="order-details-list">
                        <h5>Total Item ({paymentOrder.totalItem})</h5>
                        {
                            productNameJsx()
                        }
                        {/* <div className="mt-4" /> */}
                        <h6>Gross Total</h6>
                        {/* <h6>Discount({paymentOrder.appliedDiscount}%)</h6> */}
                        <div className="d-flex align-items-center">
                            <div className="col-12">
                                <div className="col-12 d-md-none"><h6>Coupon Code</h6></div>
                                <div className="row">
                                    <div className="col-4 d-none d-md-block"><h6>Coupon Code</h6></div>
                                    <div className="col-10 col-md-6 cstm-col-cpn-drop">
                                        {/* <select className="form-select" aria-label="Default select example" value={selectedValue} onChange={handleChange}>
                                            <option value="">Please Select</option>
                                            {couponList.map((item) => (
                                                <option key={item._id} value={item.code}>{item.code}</option>
                                            ))}
                                        </select> */}

                                        {
                                            schemeCouponList.length ?
                                                <DropdownCoupon
                                                    items={schemeCouponList}
                                                    onSelect={(item) => {
                                                        if (item) {
                                                            handleChange(item)
                                                        }
                                                    }}

                                                    // onInputChange={(item) => {
                                                    //     if (item) {
                                                    //         handleInputChange(item)
                                                    //     }
                                                    // }}
                                                    value={typeof selectedValue === 'string' ? selectedValue : selectedValue?.name || ""}
                                                /> :
                                                <DropdownCoupon
                                                    items={schemeCouponList}
                                                    value={typeof selectedValue === 'string' ? selectedValue : selectedValue?.name || ""}
                                                />
                                        }
                                    </div>
                                    <div className="col-2 text-end cstm-col-responsive">
                                        {isShowApplyBtn ? <button type="button" class="btn btn-outline-primary btn-sm rounded-3 coupon-btn-apply" onClick={() => {
                                            applyCoupon();
                                        }}>
                                            Apply
                                        </button> : <button type="button" class="btn btn-outline-primary btn-sm rounded-3 coupon-btn-remove" onClick={() => {
                                            removeCoupon();
                                        }}>
                                            Remove
                                        </button>}
                                    </div>
                                    <h6>Free Pen ({isCheckFreePen ? freepenQuantity : 0})</h6>
                                </div>
                            </div>
                        </div>
                        <h6>Total Amount</h6>
                        {/* <h6>Due Amount</h6> */}
                    </div>
                    {/* <div className="d-flex justify-content-start">
                        <BackButton className="btn payment-dtls-submit px-4" />

                    </div> */}
                </div>
                <div className="col-4 col-md-3 orderamount">
                    <div className="text-end order-details-list margin-list">
                        {
                            paymentQuantity()
                        }
                        {/* <div className="mt-4" /> */}
                        <h6>₹{paymentOrder.totalPayment ? (parseFloat(+paymentOrder.totalPayment)).toFixed(2) : 0}</h6>
                        <h6>₹{couponDiscount ? (parseFloat(+couponDiscount)).toFixed(2) : 0}</h6>

                        {/* <h6>₹{paymentOrder.discountamount ? (parseFloat(+paymentOrder.discountamount)).toFixed(2) : 0}</h6> */}
                        <div className="cstm-cpn-value-space"></div>
                        <h6>₹{isCheckFreePen ? freepenPrice : 0}</h6>
                        {/* <h6 className="text-success">₹00</h6> */}
                        <h6 className="color-ligh-red">₹{paymentOrder.finalPaymentAfterDiscount ? (parseFloat(+paymentOrder.finalPaymentAfterDiscount)).toFixed(2) : 0}</h6>
                    </div>
                    <div className="d-flex justify-content-end">
                        {/* <button
                            type="button"
                            className="btn payment-dtls-confirm px-3 px-md-5"
                            onClick={() => {
                                placeOrder();
                            }}
                        >
                            Place Order
                        </button> */}
                        {/* <button
                            type="button"
                            className="btn payment-dtls-confirm px-3 px-md-5"
                        >
                           <Link to="/customer/orders">Order History</Link> 
                        </button> */}
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="row mt-4">
                    <div className='col-md-1 checkboxstyle'>
                        <input type="checkbox" className='form-label' onClick={() => {
                            handleCheckbox();
                        }} checked={isChecked} />
                    </div>
                    <div className='col-md-10 consenttext' >
                        <p>Click here if delivery address is different from registration address</p>
                    </div>
                </div>

            </div>
            {isChecked ? <div>

                <div className="row mt-2">
                    <div className="col-md-6">
                        {/* <h6><b>Add Delivery Address</b></h6> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 mb-3">
                        <label htmlFor className="form-label lvl-clr-hd">New Delivery Address <span className='requiredFld'> * </span></label>
                        <input type="text" value={addressLine1} onChange={(event) => changeVal(event)} name='addressLine1' id className="form-control inpt_brdr_colr" placeholder />
                    </div>
                    <div className="col-md-2 mb-3 for-apper-pt">
                        <label htmlFor className="form-label lvl-clr-hd">Pin <span className='requiredFld'> * </span></label>
                        <input type='number' onInput={(e) => e.target.value = e.target.value.slice(0, 6)} value={pin} onChange={(event) => changeVal(event)} name="pin" id className="form-control inpt_brdr_colr" placeholder />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Country <span className='requiredFld'> * </span></label>
                        {
                            countries.length &&
                            <Dropdown
                                items={countries}
                                onSelect={(item) => {
                                    if (item) {
                                        let _registration = registration
                                        _registration["country"] = item.name
                                        _registration["state"] = ""
                                        _registration["town"] = ""
                                        setStateRegistrationData(_registration)
                                        //console.log(item, "item");
                                        populateStates(item.isoCode)
                                    }
                                }}
                                value={country}
                                isDisabled={true}
                            />
                        }

                    </div>
                    <div className="col-md-4 mb-3">
                        <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State <span className='requiredFld'> * </span></label>
                        {
                            states.length ?
                                <Dropdown
                                    items={states}
                                    onSelect={(item) => {
                                        if (item) {
                                            let _registration = registration
                                            _registration["state"] = item.name
                                            _registration["town"] = ""
                                            setStateRegistrationData(_registration)
                                            //console.log(item, "item");
                                            populateTowns(item.countryCode, item.isoCode)
                                        }
                                    }}
                                    value={state}
                                /> :
                                <Dropdown
                                    items={states}
                                    value={state}
                                />
                        }
                        {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control " placeholder /> */}
                    </div>
                    <div className="col-md-4 mb-3">
                        <label htmlFor className="form-label lvl-clr-hd">City/Town <span className='requiredFld'> * </span></label>
                        {/* <p>{town}  {towns.length}   {JSON.stringify(towns)}</p> */}
                        {
                            towns.length ?
                                <Dropdown
                                    items={towns}
                                    onSelect={(item) => {
                                        if (item) {
                                            let _registration = registration
                                            _registration["town"] = item.name
                                            setStateRegistrationData(_registration)
                                            //console.log(item, "item")
                                        }
                                    }}
                                    value={town}
                                /> :
                                <Dropdown
                                    items={towns}
                                    value={town}
                                />
                        }
                        {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control " placeholder /> */}
                    </div>
                </div>
            </div> : ""
            }
            <div className="row">
                <div className="d-flex justify-content-between my-4 place-order-btn-group">
                    <BackButton className="btn payment-dtls-submit px-4" />
                    <button
                        type="button"
                        className="btn payment-dtls-confirm px-3 px-md-5"
                        onClick={() => {
                            placeOrder();
                        }}
                    >
                        Place Order
                    </button>
                </div>

            </div>
        </>
    )
}