import React from "react";
export default function ShippingAddress({ customer, usertype, currentOrder, bankrefno, selectOrder, payment_type, payment_status }) {
    console.log("><><><><><>", bankrefno)
    const paymentstatus = payment_status == undefined ? (selectOrder ? selectOrder.payment_status : "") : payment_status;
    return (
        usertype != "mr" && usertype != "bu" && usertype != "nsm" && usertype != "zm" && usertype != "rm" ?
            <div className="shipng-address">
                <h5>Shipping Address</h5>
                <h6>{customer && customer.name}</h6>
                <p>
                    Street: {selectOrder && selectOrder.address && selectOrder.address.addressLine1}

                    <br />
                    City: {selectOrder && selectOrder.address && selectOrder.address.town}
                    <br />
                    PIN: {selectOrder && selectOrder.address && selectOrder.address.pin}
                    <br />
                    State/province/area: {selectOrder && selectOrder.address && selectOrder.address.state}
                    <br />
                    Phone number {customer && customer.mobileno}
                </p>


                {bankrefno != "" ? <p>Payment Ref No. : {bankrefno}</p> : ""}
                <p>Payment Mode. : {payment_type == undefined ? (selectOrder ? selectOrder.payment_type : "") : payment_type}</p>
                <p >Payment Status. : <span className={paymentstatus == 'Success' ? `success-status` : `pending-status`}>{payment_status == undefined ? (selectOrder ? selectOrder.payment_status : "") : payment_status}</span></p>


            </div> : ""
    )
}