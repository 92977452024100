import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  CUSTOMER_LOGIN_API,
  CUSTOMER_DETAIL,
  UPDATE_CUSTOMER,
  CREATE_ORDER,
  UPLOAD_PRESCRIPTION,
  GET_PRESCRIPTION,
  GET_ORDERS,
  UPLOAD_PRESCRIPTION_UPDATE_ORDER,
  CHECK_PINCODE_AVAILABLITY
} from './Slugs'
import CustomerAction from '../redux/actions/Customer.action';
// import config from '../../config/knorish.config';
// import AuthAction from '../redux/actions/Auth.action'
// import Validation from '../models/index.model'
// import { STRINGS } from '../utils/strings';
// import TenantInfoAction from '../redux/actions/TenantInfo.action';

class CustomerService extends Base {
  constructor(props) {
    super(props)
  }

  customerLogin(data) {
    return this.post(CUSTOMER_LOGIN_API, data);
  }

  getCustomer() {
    return this.get(CUSTOMER_DETAIL);
  }

  updateCustomer(data) {
    return this.post(UPDATE_CUSTOMER, data)
  }

  createOrder(data) {
    return this.post(CREATE_ORDER, data)
  }

  uploadPrescription(data) {
    return this.post(UPLOAD_PRESCRIPTION, data, false)
  }
  uploadPrescriptionUpdateOrder(data) {
    return this.post(UPLOAD_PRESCRIPTION_UPDATE_ORDER, data, false)
  }

  getPrescription(payload) {
    return new Promise(async resolve => {
      try {
        let response = await this.post(GET_PRESCRIPTION, payload)
        if (response.data && response.data.success) {
          await store.dispatch(CustomerAction.savePrescription(response.data.data.prescription))
        }
        resolve({ success: true })
      } catch (error) {
        resolve({ success: false })
      }
    })
  }

  getOrders(data) {
    return this.post(GET_ORDERS, data, false)
  }

  checkPincodeAvailablity(data) {
    return this.post(CHECK_PINCODE_AVAILABLITY, data)
  }

}

export default new CustomerService()