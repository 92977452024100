import React from "react";
import config from "../../config/emrok.config";
import { Link } from "react-router-dom";
export default function CustomerFooter() {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="footer">
                            <div className="d-flex flex-md-row flex-column justify-content-between align-items-center">
                                <div>
                                    <p className="m-0 footer-copyright-text">Copyright © 2024 Wockhardt Limited. All Rights Reserved.</p>
                                </div>
                                <div>
                                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-center">
                                        {/* <a href={`${config.serviceUrl}/images/product_img/privacy_policy.pdf`} className="privacy-policy-text me-2" target="_blank">Privacy Policy</a> */}

                                        {/* <Link to="/customer/privacy-policy" className="privacy-policy-text me-2">Privacy Policy</Link> */}
                                        <a href='https://www.wockhardt.com/wp-content/uploads/2020/05/wockhardt-privacy-policy.pdf' className="privacy-policy-text me-2" target="_blank">Privacy Policy</a>
                                        <a href='https://www.wockhardt.com/terms-and-conditions/' className="privacy-policy-text me-2" target="_blank">Terms &amp; Conditions</a>
                                        <a href='/assets/pdf/Return & Refund Policy.pdf' className="privacy-policy-text" target="_blank">Return & Refund Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> {/* Footer end*/}
                </div>
            </footer>
        </>
    )
}