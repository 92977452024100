import React, { useEffect } from "react";
import ModalCheckIcon from "../../assets/Svg/ModalCheckIcon";
import { useNavigate } from 'react-router-dom';

export default function OrderPlacedModal({ currentOrder, navigateUrl }) {
    const navigate = useNavigate();

    useEffect(() => {
        // Open the modal when the component mounts
        const modalElement = document.getElementById('orderPlaced');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    }, []);

    // const handleNavigate = () => {
    //     navigate(navigateUrl);
    //     const modalBackdrop = document.querySelector('.modal-backdrop');
    //     if (modalBackdrop) modalBackdrop.remove();
    // };


    const handleNavigate = () => {
        const modalElement = document.getElementById('orderPlaced');
        const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide(); // Hides the modal programmatically

            // Wait until the modal is hidden before disposing and navigating
            modalElement.addEventListener('hidden.bs.modal', () => {
                // Properly dispose of modal instance
                modalInstance.dispose();

                // Remove any remaining backdrop
                const modalBackdrop = document.querySelector('.modal-backdrop');
                if (modalBackdrop) modalBackdrop.remove();

                // Ensure body is scrollable by removing 'modal-open' class
                document.body.classList.remove('modal-open');

                // Navigate to the provided URL
                navigate(navigateUrl);

                // If page still doesn't scroll, reset overflow
                document.body.style.overflow = 'auto';
            });
        }
    };

    return (
        <div
            className="modal modal-xs fade"
            id="orderPlaced"
            aria-hidden="true"
            aria-labelledby="paymentSuccessModalLabel"
            tabIndex={-1}
            data-keyboard="false"
            backdrop="static"
        >
            <div className="modal-dialog modal-dialog-centered modal-payment-success">
                <div className="modal-content border-0">
                    <div className="modal-check-icon">
                        <ModalCheckIcon />
                    </div>
                    <div className="modal-header border-0 pt-5 mx-auto">
                        <h1
                            className="modal-title fs-5 text-center fw-bold"
                            id="paymentSuccessModalLabel"
                        >
                            Your Order Has been placed!
                        </h1>
                    </div>
                    <div className="modal-body">
                        <h1
                            className="modal-title fs-5 text-center fw-bold mx-auto"
                            id="paymentSuccessModalLabel"
                        >
                            Order ID-{currentOrder ? currentOrder.order_id : ""}
                        </h1>
                    </div>
                    <div className="modal-footer border-0">
                        <button className="btn bg-primary w-100" onClick={handleNavigate}>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
