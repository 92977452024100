import React from "react";
import config from "../../../config/emrok.config";
import { Link } from "react-router-dom";
export default function AdminFooter() {
    return (
        <>
            <footer className="footer--admin">
                <div className="container-fluid">
                    <div className="d-flex flex-md-row flex-column justify-content-between align-items-center">
                        <div>
                            <p className="m-0 footer-copyright-text">
                                Copyright © 2024<span className="updateYear" /> Wockhardt Limited. All
                                Rights Reserved.
                            </p>
                        </div>
                        <div>
                            <div className="d-flex flex-wrap flex-md-nowrap justify-content-center">
                                {/* <Link to="/customer/privacy-policy" className="privacy-policy-text me-2">Privacy Policy</Link>
                                <a href={`${config.serviceUrl}/images/product_img/tems_condition.pdf`} className="privacy-policy-text" target="_blank">Terms &amp; Conditions</a> */}
                                <a href='https://www.wockhardt.com/wp-content/uploads/2020/05/wockhardt-privacy-policy.pdf' className="privacy-policy-text me-2" target="_blank">Privacy Policy</a>
                                <a href='https://www.wockhardt.com/terms-and-conditions/' className="privacy-policy-text me-2" target="_blank">Terms &amp; Conditions</a>
                                <a href='/assets/pdf/Return & Refund Policy.pdf' className="privacy-policy-text" target="_blank">Return & Refund Policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}