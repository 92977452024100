import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  ADMIN_INFO,
  USER_LIST,
  SEARCH_BY_DOCTOR,
  DOWNLOAD_QR,
  GET_ALL_CALL_CENTER,
  GET_ALL_ORDER_DETAILS,
  GET_CUSTOMER_COUNT,
  UPDATE_CALL_CENTER,
  CREATE_CALL_CENTER,
  REMOVE_CALL_CENTER,
  GET_ALL_STOCKIST,
  UPDATE_STOCKIST,
  GET_ALL_ADMIN_USERS,
  UPDATE_ADMIN_USER,
  CREATE_ADMIN_USER,
  ADMIN_USER_UPDATE_STATUS,
  GET_DASHBOARD_DETAILS,
  GET_GRAPH_DATA
} from './Slugs'
import AdminAction from '../redux/actions/Admin.action';
class AdminService extends Base {
  constructor(props) {
    super(props)
  }

  getAdminInfo(data) {
    return this.post(ADMIN_INFO, data);
  }
  getuserListByRole(data) {
    return this.post(USER_LIST, data);
  }
  searchByDoctor(data) {
    return this.post(SEARCH_BY_DOCTOR, data);
  }
  downloadQrCode(data) {
    return this.post(DOWNLOAD_QR, data);
  }

  getallCallCenter() {
    return this.get(GET_ALL_CALL_CENTER);
  }

  getOrderCountDetails() {
    return this.get(GET_ALL_ORDER_DETAILS);
  }

  getCustomerCount() {
    return this.get(GET_CUSTOMER_COUNT);
  }
  updateCallCenter(data) {
    return this.post(UPDATE_CALL_CENTER, data);
  }
  createCallCenter(data) {
    return this.post(CREATE_CALL_CENTER, data);
  }
  removeCallCenter(data) {
    return this.post(REMOVE_CALL_CENTER, data);
  }

  getallStockist() {
    return this.get(GET_ALL_STOCKIST);
  }

  updateStockist(data) {
    return this.post(UPDATE_STOCKIST, data);
  }

  /* admin user actions */
  getallAdminUsers() {
    return this.get(GET_ALL_ADMIN_USERS);
  }
  updateAdminUser(data) {
    return this.post(UPDATE_ADMIN_USER, data);
  }
  createAdminUser(data) {
    return this.post(CREATE_ADMIN_USER, data);
  }
  adminUserChangeStatus(data) {
    return this.post(ADMIN_USER_UPDATE_STATUS, data);
  }

  getDashBoardDetails() {
    return new Promise(resolve => {
      try {
        this.post(GET_DASHBOARD_DETAILS, {}, false, false).then(async data => {
          console.log(data, "data12333");
          if (data.data.success && data.data.data.length) {
            let payload = data.data.data[0]
            console.log(payload, "GET_DASHBOARD_DETAILS");
            await store.dispatch(AdminAction.setDashboardDetails(payload))
            resolve({ success: true })
          }
          else {
            resolve({ success: false, error: data })
          }
        })
      } catch (error) {
        console.log(error, "GET_DASHBOARD_DETAILS err");
        return resolve({ success: false, error })
      }
    })
  }

  getGraphData(params) {
    return new Promise(resolve => {
      try {
        this.post(GET_GRAPH_DATA, params, false, false).then(async data => {
          if (data.data.success && data.data.data) {
            let payload = data.data.data
            console.log(payload, "GET_GRAPH_DATA");
            if (payload.customers && payload.orders && payload.customers.length && payload.orders.length) {
              let structured_data = {
                labels: payload.customers.map(c => c.month),
                datasets: [
                  {
                    label: 'Number of Registrations',
                    data: payload.customers.map(c => c.count),
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                  },
                  {
                    label: 'Number of Orders',
                    data: payload.orders.map(o => o.count),
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                  },
                ]
              }
              let structured_options = {
                responsive: true, // Ensure the chart is responsive
                maintainAspectRatio: true,  // Enable this to prevent height from increasing rapidly
                aspectRatio: window.innerWidth < 768 ? 1 : 2,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: Math.ceil(Math.max(...payload.customers.map(c => c.count), ...payload.orders.map(o => o.count)) / 10)
                    }
                  },
                  x: {
                    ticks: {
                      font: {
                        size: window.innerWidth < 768 ? 12 : 16, // Adjust the X-axis font size for mobile
                      }
                    }
                  }
                },
                plugins: {
                  legend: {
                    position: 'top',
                    labels: {
                      font: {
                        size: window.innerWidth < 768 ? 12 : 16, // Adjust the legend font size for mobile
                        weight: 'bold'
                      }
                    }
                  },
                  title: {
                    display: true,
                    text: 'Orders vs Registrations Comparison',
                    font: {
                      size: window.innerWidth < 768 ? 18 : 24, // Adjust the title size for mobile
                      weight: 'bold'
                    }
                  },
                  tooltip: {
                    titleFont: {
                      size: window.innerWidth < 768 ? 12 : 14 // Adjust tooltip font size for mobile
                    },
                    bodyFont: {
                      size: window.innerWidth < 768 ? 10 : 12 // Adjust tooltip font size for mobile
                    }
                  }
                }
              }
              await store.dispatch(AdminAction.setGraphData({ graphData: structured_data, graphOptions: structured_options }))
              resolve({ success: true })
            }
            else {
              resolve({ success: false, error: data })
            }
          }
          else {
            resolve({ success: false, error: data })
          }
        })
      } catch (error) {
        console.log(error, "GET_GRAPH_DATA err");
        return resolve({ success: false, error })
      }
    })
  }


}

export default new AdminService()