import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  STOCKIST_DETAIL,
  STOCKIST_INFO,
  APPROVED_PRESCRIPTION,
  GET_ALL_STOCKIST,
  CHANGE_ORDER_DELIVERY_STATUS
} from './Slugs'
import StockistAction from '../redux/actions/Stockist.action';
class StockistService extends Base {
  constructor(props) {
    super(props)
  }

  getStockistDetails(data) {
    return this.post(STOCKIST_DETAIL, data);
  }

  getStockistInfo(data) {
    return this.post(STOCKIST_INFO, data);
  }

  approvedPrescription(data) {
    return this.post(APPROVED_PRESCRIPTION, data);
  }

  getAll() {
    return new Promise(async resolve => {
      try {
        let data = await this.get(GET_ALL_STOCKIST)
        console.log(data.data.data, "GET_ALL_STOCKIST");
        if (data.data.success) {
          let items = await data.data.data.length ? data.data.data.map(item => {
            return {
              ...item,
              label: item.name,
              value: item._id
            }
          }) : []
          await store.dispatch(StockistAction.setAllStockists(items))
          resolve({ success: true })
        }
      } catch (error) {
        return resolve({ success: false, error })
      }
    })
  }

  changeOrderDeliveryStatus(data) {
    return this.post(CHANGE_ORDER_DELIVERY_STATUS, data);
  }

}

export default new StockistService()