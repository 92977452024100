import React, { useEffect } from "react";
import Circle from "../../assets/Svg/Circle";
import ShppingJquery from "./ShippingJquery";
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import config from "../../config/emrok.config";
export default function TrackShipment(props) {

    console.log(props.currentOrder, "allprops");
    const navigate = useNavigate();
    useEffect(() => {
        ShppingJquery();
    }, [])


    const DisplayDateTimeComponent = () => {
        // Create a new Date object with the desired date and time (August 9, 2021, 10:00am)
        const date = new Date();

        // Define an array of month names
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Get the parts of the date (day, month, year)
        const day = String(date.getDate()).padStart(2, '0');
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        // Get the parts of the time (hours, minutes)
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Determine AM or PM
        const amOrPm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

        // Format the date and time string (e.g., "09 Aug 2021, 10:00am")
        const formattedDateTime = `${day} ${month} ${year}, ${hours}:${minutes}${amOrPm}`;

        return (
            <div>
                {formattedDateTime}
            </div>
        );
    };

    const checkWithCurrentDate = (givenDate) => {

        console.log(givenDate, "givenDate");

        const timestampMatches = givenDate.match(/\d+/);
        if (timestampMatches && timestampMatches.length > 0) {


            // const now = moment().add(1, 'day');

            // // Get the timestamp in milliseconds
            // const timestamp = now.valueOf();

            // // Get the timezone offset in minutes and format it as "+HHmm" or "-HHmm"
            // const timezoneOffsetMinutes = now.utcOffset();
            // const timezoneOffsetHours = Math.floor(Math.abs(timezoneOffsetMinutes) / 60);
            // const timezoneOffsetMinutesFormatted = String(Math.abs(timezoneOffsetMinutes) % 60).padStart(2, '0');
            // const timezoneOffsetSign = timezoneOffsetMinutes >= 0 ? '+' : '-';
            // const timezoneOffsetFormatted = `${timezoneOffsetSign}${timezoneOffsetHours}${timezoneOffsetMinutesFormatted}`;

            // // Construct the formatted date string
            // const formattedDate = `/Date(${timestamp}${timezoneOffsetFormatted})/`;

            // console.log(formattedDate,"formattedDate00");
            // return true;

            const timestamp = parseInt(timestampMatches[0]);

            // Create a moment object from the extracted timestamp
            const givenMoment = moment(timestamp);

            // Get the current date and time
            const now = moment();

            // Compare the current date with the given date
            const isSameOrGreater = now.isSameOrAfter(givenMoment, 'day');

            // Format the dates for display
            const formattedGivenDate = givenMoment.format('D MMM YYYY, h:mm a');
            const formattedCurrentDateTime = now.format('D MMM YYYY, h:mm a');

            // Update the state with the formatted dates
            // setCurrentDateTime(formattedCurrentDateTime);

            console.log('Is current date same or greater:', isSameOrGreater);


            // Get the current date and time
            if (isSameOrGreater) {
                return true;
            } else {
                return false;
            }


            // return true;
        }
    }

    return (
        <>
            <div className="col-md-6 track-shipping-ft mt-4">
                <div className="track-point-pt mt-4">
                    <h5>Track Shipment</h5>
                    <div className="col-md-12 col-lg-12">
                        <div id="tracking-pre" />
                        <div id="tracking">
                            <div className="tracking-list">
                                {/* <div className={props.orderConfirmdDate ? "tracking-item current-tracking-item" : "tracking-item"}>
                                    <div className="tracking-icon status-current status-intransit">
                                        <Circle />
                                    </div>
                                    <div className="tracking-content">
                                        Order Placed<span>{props.orderplacedDate ? moment(props.orderplacedDate).format('DD MMM YYYY, hh:mm a') : DisplayDateTimeComponent()}</span>
                                    </div>
                                </div> */}
                                {props.currentOrder.orderStatus != "Canceled" ? <div>
                                    <div className={props.currentOrder.deliveryStatus == "delivered" ? "tracking-item current-tracking-item" : "tracking-item"}>
                                        <div className="tracking-icon status-current status-intransit">
                                            <Circle />
                                        </div>
                                        <div className="tracking-content">
                                            Order Placed<span>{props.orderplacedDate ? moment(props.orderplacedDate).format('DD MMM YYYY, hh:mm a') : DisplayDateTimeComponent()}</span>
                                        </div>
                                    </div>
                                    {/* <div className={props.orderPickUpdate && checkWithCurrentDate(props.orderPickUpdate) ? "tracking-item current-tracking-item" : "tracking-item"}>
                                        <div className={props.orderConfirmdDate ? "tracking-icon status-current status-intransit" : "tracking-icon status-intransit"}>
                                            <Circle />
                                        </div>
                                        <div className="tracking-content text-muted">
                                            Order Confirmed
                                            <span>{props.orderConfirmdDate ? moment(props.orderConfirmdDate).format('DD MMM YYYY, hh:mm a') : ""}</span>
                                            <span>{props.orderConfirmdDate ? "Order will be delivered within 48 hrs" : ""}</span>
                                        </div>
                                    </div> */}
                                    {/* <div className={props.currentOrder.deliveryStatus == "delivered" ? "tracking-item current-tracking-item" : "tracking-item"}>
                                        <div className={props.orderPickUpdate && checkWithCurrentDate(props.orderPickUpdate) ? "tracking-icon status-current status-intransit" : "tracking-icon status-intransit"}>
                                            <Circle />
                                        </div>
                                        <div className="tracking-content text-muted">
                                            Ready to Pickup
                                            <span>{props.orderPickUpdate && checkWithCurrentDate(props.orderPickUpdate) ? (

                                                <span>{moment(props.orderPickUpdate).format('DD MMM YYYY, hh:mm a')} (AWB No: <a href={config.bdUrl + props.awbno} target="_blank">{props.awbno}</a>)

                                                    <span>Click the AWB No. to track your shipment</span>
                                                </span>

                                            )


                                                : (
                                                    ""
                                                )}


                                            </span>
                                            


                                        </div>
                                    </div> */}
                                    <div className="tracking-item">
                                        <div className={props.currentOrder.deliveryStatus == "delivered" ? "tracking-icon status-current status-intransit" : "tracking-icon status-intransit"}>
                                            <Circle />
                                        </div>
                                        <div className="tracking-content text-muted">
                                            Order Delivered
                                            <span>{props.currentOrder.deliveryStatus == "delivered" ? moment(props.currentOrder.deliveryDate).format('DD MMM YYYY, hh:mm a') : ""}</span>
                                            {/* <span>10 Aug 2021, 02:00pm</span> */}
                                        </div>
                                    </div>

                                </div> :
                                    <div>
                                        <div className="tracking-item current-tracking-item">
                                            <div className="tracking-icon status-current status-intransit">
                                                <Circle />
                                            </div>
                                            <div className="tracking-content">
                                                Order Placed<span>{props.orderplacedDate ? moment(props.orderplacedDate).format('DD MMM YYYY, hh:mm a') : DisplayDateTimeComponent()}</span>
                                            </div>
                                        </div>

                                        {/* {props.currentOrder.orderStatus == 'RTO' ?

                                            <div className="tracking-item current-tracking-item">
                                                <div className="tracking-icon status-current status-intransit">
                                                    <Circle />
                                                </div>
                                                <div className="tracking-content">
                                                    RTO<span>{props.rtochangeDate ? moment(props.rtochangeDate).format('DD MMM YYYY, hh:mm a') : ""}</span>
                                                </div>
                                            </div> : ""

                                        } */}



                                        <div className="tracking-item current-tracking-item">
                                            <div className="tracking-icon status-current status-intransit">
                                                <Circle />
                                            </div>
                                            <div className="tracking-content">
                                                Order Canceled<span>{props.refundchangeDate ? moment(props.refundchangeDate).format('DD MMM YYYY, hh:mm a') : ""}</span>
                                            </div>
                                        </div>

                                        {/* <div className="tracking-item current-tracking-item">
                                            <div className="tracking-icon status-current status-intransit">
                                                <Circle />
                                            </div>
                                            <div className="tracking-content">
                                                Order Cancelled<span>{props.currentOrder.cancel_date ? moment(props.currentOrder.cancel_date).format('DD MMM YYYY, hh:mm a') : DisplayDateTimeComponent()}</span>
                                            </div>
                                        </div> */}
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="dwn-btn-trk mt-4">
                        {/* <div>
                            {props.currentOrder == undefined || props.currentOrder.orderStatus != "cancelled" ? <button type="" className="feedbk-wrt" onClick={() => props.orderCancel ? props.orderCancel() : null}>
                                Cancel
                            </button> : ""}
                        </div> */}
                        {/* <div>
                            <button type="" className="feedbk-wrt" onclick="">
                                Track Order
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}