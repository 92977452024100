
export const SET_ADMIN = "SET_ADMIN";
export const CLEAR_STATE = "CLEAR_COMMON_STATE";
export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";
export const STORE_SEARCH_VALUE = "STORE_SEARCH_VALUE";
export const SET_DASHBOARD_DETAILS = "SET_DASHBOARD_DETAILS"
export const SET_GRAPH_DATA = "SET_GRAPH_DATA"



