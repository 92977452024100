import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../components/Reusable/WithRouter";
import config from "../../../config/emrok.config";
import Common from "../../../hoc/Common.hoc";
import axios from "axios";
import moment from "moment";
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import "../../../assets/css/admin/style.css";
import "../../../assets/css/admin/responsive.css";
import DropdownDoctorRuntime from "../../../components/SearchableDropdown/DropdownDoctorRuntime.component";
import AdminService from "../../../services/Admin.service";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
class UserManagmentDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() { }

  navigateTo = (type) => {
    if (type == "admin_user") {
      this.props.navigate("/admin/user-managment/admin-users");
    } else if (type == "stockist") {
      this.props.navigate("/admin/user-managment/stockist");
    } else if (type == "callcenter") {
      this.props.navigate("/admin/user-managment/callcenter");
    }
  };
  render = () => {
    return (
      <>
        <div className="layout-wrapper">
          <AdminHeader />
          <AdminSidebar />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row g-4">
                  <div className="col-md-5">
                    <h5 className="mb-20">User Management</h5>
                  </div>
                  {/* Row end */}
                  <div className="row g-4">
                    <div className="col-md-6">
                      <div className="card card-animate">
                        <a
                          className=""
                          href="javascript:void(0)"
                          onClick={() => {
                            this.navigateTo("admin_user");
                          }}
                        >
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <a className="" href="javascript:void(0)">
                                <div>
                                  <p className="fw-medium mb-0">
                                    Admin
                                  </p>
                                  <h2 className="mt-4 ff-secondary fw-semibold">
                                    {/* <span className="counter-value" data-target="97.66" />k */}
                                  </h2>
                                </div>
                              </a>
                            </div>
                          </div>
                        </a>
                        {/* end card body */}
                      </div>
                      {/* end card*/}
                    </div>
                    <div className="col-md-6">
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <a
                              className="w-100"
                              href="javascript:void(0)"
                              onClick={() => {
                                this.navigateTo("stockist");
                              }}
                            >
                              <div>
                                <p className="fw-medium mb-0">
                                  Stockist
                                </p>
                                <h2 className="mt-4 ff-secondary fw-semibold">

                                </h2>
                                <p className="mb-0 text-muted">

                                </p>
                              </div>
                            </a>

                          </div>
                        </div>

                      </div>

                    </div>

                    {/* end col*/}
                    <div className="col-md-6">
                      <div className="card card-animate">
                        <a
                          className=""
                          href="javascript:void(0)"
                          onClick={() => {
                            this.navigateTo("callcenter");
                          }}
                        >
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <a className="" href="javascript:void(0)">
                                <div>
                                  <p className="fw-medium mb-0">
                                    Call Center
                                  </p>
                                  <h2 className="mt-4 ff-secondary fw-semibold">
                                    {/* <span className="counter-value" data-target="97.66" />k */}
                                  </h2>

                                </div>
                              </a>

                            </div>
                          </div>
                        </a>
                        {/* end card body */}
                      </div>
                      {/* end card*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminFooter />
          </div>
        </div>
      </>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
  };
};

const mapStateToProps = (state) => {
  return {
    OrderReducer: state.OrderReducer,
    CustomerReducer: state.CustomerReducer,
    CommonReducer: state.CommonReducer,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Common(UserManagmentDashboard)));
