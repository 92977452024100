import {
  SET_ADMIN,
  CLEAR_STATE,
  SET_ACTIVE_MENU,
  STORE_SEARCH_VALUE,
  SET_DASHBOARD_DETAILS,
  SET_GRAPH_DATA
} from '../types/Admin.type'
class AdminAction {
  setAdmin = (data) => {
    console.log(">>>>>>>>>>>>>>>>", data)
    return { type: SET_ADMIN, payload: data }
  }
  logout = () => {
    return { type: CLEAR_STATE, payload: null }
  }

  setActiveMenuItem = (data) => {
    // alert(data)
    return { type: SET_ACTIVE_MENU, payload: data }
  }

  setSearchValue = (data) => {
    // alert(data)
    return { type: STORE_SEARCH_VALUE, payload: data }
  }

  setDashboardDetails = data => {
    return { type: SET_DASHBOARD_DETAILS, payload: data }
  }

  setGraphData = data => {
    return { type: SET_GRAPH_DATA, payload: data }
  }

}
export default new AdminAction()