import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import StockistAction from '../../../redux/actions/Stockist.action';
class StockistManagmentDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            stockistList: []
        }

    }


    componentDidMount() {
        this.getStockistDetails();
    }

    getStockistDetails = async () => {
        try {
            this.setState({ stockistList: [] })

            let res = await AdminService.getallStockist()
            console.log(res, 123333)
            if (res.data.success) {
                console.log(res.data.data, "lasjdhjkash");
                this.setState({ stockistList: res.data.data });
            }

        } catch (error) {

        }
    }
    handleEditStockist = async (event) => {
        event.preventDefault();
        const { stockistList } = this.state;
        const id = event.target.id;
        const selectedStockist = await stockistList.filter(item => item._id == id)
        console.log(selectedStockist, "selectedStockist");
        if (selectedStockist.length) {

            // let order_details
            //  selectedPatientDetails[0].customer['order_id'] = selectedPatientDetails[0].order_i

            // console.log(this.props.callcenterdetails, "callcender reducer")
            // this.props.setCallCenter(callCenterDetails);
            this.props.setStockist(selectedStockist[0])
        } else {
            // this.props.setCustomer(selectedPatientDetails)
        }

        this.props.navigate('/admin/user-managment/stockist/edit');
    }

    handleAdd = () => {

    }
    setOrderHistoryData = (data) => {
        let resp = []
        let pincodeString = "";
        for (let i = 0; i < data.length; i++) {
            if (data[i].pincodes.length > 0) {
                pincodeString = data[i].pincodes.map(item => item.pincode).join(', ');
            }

            resp.push({
                _id: data[i]._id,
                name: data[i] ? data[i].name : "",
                mobileno: data[i].mobileno,
                stockist_code: data[i].stockist_code,
                pincodes: pincodeString

            })
        }
        return resp
    }

    handleClickHome = () => {
        this.props.navigate('/admin/user-managment')
    }

    render = () => {
        const { stockistList } = this.state;
        let _data = this.setOrderHistoryData(stockistList)
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-5'>
                                        <h5 className='mb-20'>Stockist Management</h5>
                                    </div>
                                    <div className='col-md-7'>
                                        <a href={void (0)} > <div className="btn-home flotright mx-4" onClick={this.handleClickHome}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                            Back
                                        </div>   </a>

                                    </div>
                                    {/* Row end */}
                                    {/* <div className='col-md-6'>
                                        <div className="btn-home flotright">
                                            <a href="javascript:void(0);" onClick={this.handleAdd}>
                                                Add
                                            </a>
                                        </div>
                                    </div> */}
                                    {/* Row end */}
                                    <div className="row g-4">

                                        <div className="table-responsive stockist-list-table">


                                            {
                                                _data && _data.length ?
                                                    <CustomDatatable
                                                        pagination={true}
                                                        columns={[
                                                            {
                                                                name: 'Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Stockist Code',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Mobile No.',
                                                                // selector: row => row.mr_name,

                                                            },

                                                            {
                                                                name: 'Pincode',
                                                                // selector: row => row.createdAt,

                                                            }
                                                        ]}
                                                        data={this.setOrderHistoryData(stockistList)}
                                                        pageName="stockistmanagment"
                                                        handleEditStockist={this.handleEditStockist}



                                                    />
                                                    : <p className="norecord"><strong>No Records Found</strong></p>
                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
        setStockist: (stockistdetails) => dispatch(StockistAction.setStockist(stockistdetails))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(StockistManagmentDashboard)))